<template>
  <div class="second-content">
    <!-- <img src="@/assets/about/2-min.png" alt="backdrop" id="backdrop-full" /> -->

    <div class="content-title">
      <h1 v-if="lang === 'id'">DENGAN MISI</h1>
      <h1 v-else>With Our Mission</h1>
    </div>
    <div class="main-content">
      <div class="inovatif">
        <img src="@/assets/about/bulb-min.png" class="imgContent" alt />
        <div v-if="lang==='id'" class="desc">
          <h1>Inovatif</h1>
          <p>Literasi digital menjadi hak setiap orang dan kamu bisa mendapatkannya secara gratis di semua lokasi di HIGOspot</p>
        </div>
        <div class="desc" v-else>
          <h1>Innovative</h1>
          <p>HIGO strive to initiate solutions for modern era problems</p>
        </div>
      </div>
      <div class="realible">
        <img src="@/assets/about/connect-min.png" alt class="imgContent" />
        <div v-if="lang==='id'" class="desc">
          <h1>Reliabel</h1>
          <p>Selalu konsisten bekerja sama dengan banyak
pihak untuk memberikan kemudahan akses internet gratis</p>
        </div>
        <div class="desc" v-else>
          <h1>Reliable</h1>
          <p>HIGO are consistently developing new and innovative solutions</p>
        </div>
      </div>
      <div class="relevan">
        <img src="@/assets/about/city-min.png" alt class="imgContent" />
        <div class="desc" v-if="lang==='id'">
          <h1>Relevan</h1>
          <p>Kemudahan akses internet gratis memberikan dampak
langsung untuk literasi digital & smart city</p>
        </div>
        <div class="desc" v-else>
          <h1>Relevant</h1>
          <p>HIGO build solutions that are relevant for small, medium and large corporations</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['lang']
}
</script>

<style scoped>
.second-content {
  width: 100%;
  height: 630px;
  /* min-height: calc(100vh - 3rem); */
  position: relative;
  padding: 5.5rem 0;
}

.content-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 5rem;
}

.content-title h1 {
  color: #0245ab;
  font-size: 2rem;
  margin-top: 2rem;
}

.main-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.5fr 1rem 1.5fr 1rem 1.5fr;
  grid-template-columns: 1.5fr 1.5fr 1.5fr;
  grid-column-gap: 2rem;
  width: 90%;
  margin: 0 auto;
}

.inovatif,
.realible,
.relevan {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 2.5fr 1fr;
  grid-template-rows: 2.5fr 1fr;
  place-items: center;
}

.desc {
  display: flex;
  flex-direction: column;
  color: #0245ab;
  text-align: center;
  width: 100%;
  height: 100%;
}

.desc h1 {
  margin-bottom: 20px;
  color: #61615b;
}

.desc p {
  font-size: .9rem;
  color: #61615b;
  width: 100%;
  line-height: 1.7;
}

.slide-container {
  min-height: 100% !important;
}

.imgContent {
  width: 8rem;
  height: auto;
}

#backdrop-full {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -99;
}

@media screen and (max-width: 991px) and (min-width: 576px) {
  .desc p {
    font-size: 1rem !important;
    width: 60%;
    margin: 0 auto;
  }

  .content-title h1 {
    font-size: 32px !important;
  }
}

@media screen and (max-width: 991px) {
  .main-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .content-title h1 {
    text-align: center;
  }

  .inovatif,
  .realible,
  .relevan {
    width: 100vw;
    margin: 10px 0;
    padding: 10px;
    height: 10%;
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
  }

  .imgContent {
    margin-bottom: 2rem;
  }

  #backdrop-full {
    display: none;
  }

  .content-title {
    height: 10vh;
    margin-bottom: 10px;
  }

  .desc {
    padding: 0 20px;
  }

  .second-content {
    padding-bottom: unset;
  }
}

@media screen and (min-width: 1201px) {
  .main-content {
    width: 1140px;
    margin: 0 auto;
  }

  .content-title h1 {
    margin-top: 20px;
  }

  .inovatif,
  .realible,
  .relevan {
    -ms-grid-rows: 2.5fr 1.5fr;
    grid-template-rows: 2.5fr 1.5fr;
  }

  .main-content {
    grid-column-gap: 4.3rem;
  }
}

@media screen and (max-width: 990px) {
  .second-content {
    height: auto;
  }

  .second-content .main-content {
    width: 100%;
  }

  #solution-case {
    padding: 1rem;
  }

  .desc p {
    font-size: .9rem;
    color: #61615b;
    width: 100%;
    line-height: 1.7;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .inovatif,
  .realible {
    -ms-grid-rows: 1.7fr 1fr;
    grid-template-rows: 1.7fr 1fr;
  }

  .relevan {
    -ms-grid-rows: 1.7fr 1fr;
    grid-template-rows: 1.7fr 1fr;
  }
}

@media (min-width: 1200px) {
  .content-title h1 {
    font-size: min(2.3vw, 28px);
  }

  .main-text p {
    font-size: min(1.3vw, 18px);
  }

  .desc h1 {
    font-size: min(1.9vw, 22px);
  }

  .desc p {
    font-size: min(1.3vw, 16.5px);
  }
}
</style>
