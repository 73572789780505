<template>
  <div id="solution-case">
    <img src="@/assets/about/sun-min.png" alt="">
    <div id="sol-desc" v-if="lang === 'id'">
      <h3>UNTUK MEMBERIKAN SOLUSI</h3>
      <p>Bekerja sama dengan banyak pihak, untuk sama-sama membantu banyak orang mendapatkan akses internet gratis dengan mudah</p>
    </div>

    <div id="sol-desc" v-else>
      <h3>ACTIVELY CREATING SOLUTIONS</h3>
      <p>We are working together with several entities to ensure digital infrastructure and internet access for everyone</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['lang']
}
</script>

<style scoped>
#solution-case {
  width: 100%;
  height: 400px;
  display: grid;
  padding: 3rem;
  grid-template-columns: .5fr 1fr;
}

#solution-case img {
  margin: 0 auto;
  align-self: center;
}

#sol-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 3rem;
}

#sol-desc h3 {
  color: #0245ab;
  margin-bottom: 1rem;
  font-size: 2em;
}

#sol-desc p {
  line-height: 1.8;
  font-size: .9rem;
  color: #61615b;
}

@media screen and (min-width: 1200px) {
  #solution-case {
    width: 1140px;
    margin: 0 auto;
  }

  #sol-desc h3 {
    font-size: min(2vw, 28px);
  }

  #sol-desc p {
    font-size: min(1.2vw, 18px)
  }
}

@media screen and (max-width: 767px)  {
  #solution-case {
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 1rem;
  }

  #sol-desc {
    padding: 0;
    align-items: center;
  }

  #sol-desc h3, #sol-desc p {
    text-align: center;
  }

  #sol-desc h3 {
    font-size: 1.2rem;
    line-height: 1.8;
  }

  #sol-desc p {
    font-size: .9rem;
    width: 90%;
  }

  #solution-case img {
    width: 55%;
    max-width: 12rem;
  }
}

@media screen and (max-width: 575px) {
  #sol-desc p {
    font-size: .9rem;
    width: 95%;
  }

  #solution-case {
    margin-bottom: 2rem;
  }
}
</style>
