<template>
  <div class="about-container">
    <!-- backdrop -->

    <!-- navigation -->
    <Navbar @lang="getLang" color="white" />

    <!-- first content -->
    <div class="contentabout" :style="{'min-height': (windowHeight + 630) + 'px'}">

      <div id="backdrop-container" :style="{'min-height': windowHeight + 'px', height: '100vh'}">
        <img src="@/assets/about/bg-top-min.png" alt="backdrop" id="backdrop-wave"/>
      </div>
      <FirstContent :lang="lang" :style="{'min-height': windowHeight + 'px'}" />

      <!-- Second Content -->
      <SecondContent :lang="lang" :style="{'height': '630 px'}"/>
    </div>

    <!-- Third Content -->
    <!-- <ThirdContent /> -->
    <GiveSolution :lang="lang"/>

    <div id="container-bottom">
      <img v-if="windowWidth >= 576" src="@/assets/about/bg-bot-min.png" alt="" id="bg-bot">
      <div id="text-content-bottom">
        <h3>{{lang === 'id' ? 'Ambil Bagianmu' : 'Take Your Part'}}</h3>
        <p>{{lang === 'id' ? 'Untuk ikut serta berkontribusi memberikan kemudahan akses intenet gratis di Indonesia' : "Let's contribute to provide Free Internet Access in Indonesia"}}</p>

        <div id="btn-home" @click.prevent="goCareers">
          <p>{{lang === 'id' ? 'Gabung Bersama Kami' : 'Join Our Team'}}</p>
        </div>
      </div>
    </div>

    <!-- Footer  -->
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import FirstContent from '@/components/About/FirstContent'
import SecondContent from '@/components/About/SecondContent'
import GiveSolution from '@/components/About/GiveSolution'
import Footer from '@/components/Footer'

export default {
  components: { Navbar, FirstContent, SecondContent, Footer, GiveSolution },
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  },
  created () {
    window.scrollTo(0, 0)
    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight
  },
  methods: {
    goCareers () {
      sessionStorage.setItem('from-url-career', window.location.href)
      this.$router.push('/careers')
    },
    getLang (value) {
      this.lang = value
    }
  },
  metaInfo: {
    title: 'HIGO | Tentang Kami ',
    meta: [
      {
        name: 'description',
        content:
          'WiFi Advertising Platform yang membantu brand, kafe atau resto, event melakukan iklan, survey lewat WiFi marketing.'
      },
      {
        name: 'keywords',
        content:
          'WiFi Marketing Jakarta, Digital Marketing, HIGO WiFi Advertising Platform di Jakarta, Tim HIGO'
      }
    ]
  }
}
</script>

<style scoped>
.about-container {
  width: 100%;
  min-height: 200vh;
  overflow-x: hidden;
}

.aboutcontent {
  position: absolute;
}

.contentabout {
  width: 100%;
  position: relative;
  padding-bottom: 10px;
}

#pathmobile {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80%;
}

#backdrop-container {
  width: 100%;
  position: absolute;
}

#backdrop-wave {
  position: absolute;
  width: 100%;
  z-index: -9;
  bottom: -33%;
  height: 85%;
}

#container-bottom {
  width: 100%;
  height: 400px;
  position: relative;
  color: #fff;
  display: grid;
  place-items: center;
  padding: 3rem;
}

#bg-bot {
  position: absolute;
  width: 100%;
  height: 150%;
  bottom: 0;
  left: 0;
  z-index: -1;
}

#text-content-bottom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.8;
}

#text-content-bottom h3 {
  font-size: 1.8rem;
  text-align: center;
  margin: 1.5rem 0;
}

#text-content-bottom p {
  text-align: center;
  font-size: .9rem;
}

#btn-home {
  max-width: 19rem;
  width: 80%;
  margin: 3rem auto 0 auto;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255,212,0);
  color: rgb(9,0,101);
  cursor: pointer;
  z-index: 2;
  border-radius: 25px;
  --color: #ef6eae;
  --hover: #ef8f6e;
}

button {
  color: var(--color);
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
/* #btn-home:hover, #btn-home:focus {
  border-color: var(--hover);
  color: #fff;
  background-color: rgb(9,0,101);
} */

#btn-home p{
  font-size: 16px;
  font-weight: 600;
}

#btn-home:hover {
  box-shadow: 0 0 10px rgb(255,212,0), 0 0 40px rgb(255,212,0), 0 0 80px rgb(255,212,0);
}

@media screen and (min-height: 910px) {
  #backdrop {
    min-height: 200vh;
  }
}

@media screen and (max-height: 800px) and (min-height: 766px) {
  #backdrop {
    min-height: 210vh;
  }
}

@media screen and (max-width: 380px) and (max-height: 670px) {
  #pathmobile {
    height: 84%;
  }

  .second-content {
    padding-top: 55px;
  }
}

@media screen and (max-height: 765px) and (min-height: 700px) {
  #backdrop {
    min-height: 220vh;
  }
}

@media screen and (max-height: 725px) {
  #backdrop {
    top: 45vh;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  #pathmobile {
    height: 100%;
    top: 15.8rem;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  #pathmobile {
    height: 88%;
    top: 15rem;
  }

  .contentabout {
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  #pathmobile {
    height: 88%;
  }
}

@media screen and (max-width: 767px) {
  #container-bottom {
    padding: .5rem 5%;
  }
  #text-content-bottom h3 {
    font-size: 1.5rem;
    line-height: 1.8;
  }
}

@media (max-width: 575.98px) {
  #container-bottom {
    background: rgb(1,205,232);
background: -moz-linear-gradient(90deg, rgba(1,205,232,1) 0%, rgba(5,82,201,0.9900911048012955) 100%);
background: -webkit-linear-gradient(90deg, rgba(1,205,232,1) 0%, rgba(5,82,201,0.9900911048012955) 100%);
background: linear-gradient(90deg, rgba(1,205,232,1) 0%, rgba(5,82,201,0.9900911048012955) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01cde8",endColorstr="#0552c9",GradientType=1);
}
}
@media screen and (min-width: 1200px) {
  #text-content-bottom {
    width: 1140px;
    margin: 0 auto;
    padding: 0 8rem;
  }

  #text-content-bottom h3 {
    font-size: min(2vw, 28px);
  }

  #text-content-bottom p {
    font-size: min(1.2vw, 18px)
  }
}
</style>
