<template>
  <div class="first-content">
    <!-- image -->
    <!-- round shape -->
    <!-- <img src="@/assets/about/round-shape.png" alt="round-shape" id="round-shape" /> -->

    <!-- little shape -->
    <vue-element-loading :active="loading" is-full-screen>
        <img src="@/assets/gif/Motion-Logo-.gif" style="width: 50%; max-width: 250px;">
    </vue-element-loading>

    <div class="about-content">
      <div v-if="lang === 'id'" class="main-text">
        <h1>CERITA SINGKAT</h1>
        <p>
          Keterbatasan akan smart city dan kenyataan tidak semua orang mudah mendapat akses internet gratis, membuat HIGO bekerja sama dengan beberapa lokasi untuk merubahnya menjadi
          HIGOspot Free WiFi
        </p>
      </div>

      <div v-else class="main-text">
        <h1>Short Story</h1>
        <p>
          The lack of data for smart city and the fact that not everyone can get free internet access waken HIGO’s dream to work with several entities and make sure that people can get Free Internet access through HIGOspot location
        </p>
      </div>
    </div>

    <div class="back-container">
      <div class="balik-layar">
        <div class="text">
          <h1 v-if="lang === 'id'">Di balik Layar</h1>
          <h1 v-else>Behind the Scenes</h1>
        </div>
        <div class="people-profile">
          <div :class="[windowWidth < 577 ? 'mobile-carousel' : null]" style="width: 100%; height: 100%; display: grid; place-items: center;">
            <Carousel3d v-if="teamData.length" :autoplay="true" :animationSpeed="800" :perspective="0" :loop="true" :inverse-scalling="1500" :space="300" :style="{height: `${windowWidth < 577 ? '300px' : '85%'}`}">
            <!-- <Carousel3d :autoplay="true" :animationSpeed="800" :perspective="0" :loop="true" :inverse-scalling="1500" :space="300" :style="{height: `${windowWidth < 577 ? '300px' : '85%'}`}"> -->
              <!-- Version 2 -->
              <slide :index="index" class="slide-container"
              v-for="(team, index) in teamData" :key="index"
              >
                <img @load="imgSlide" :src="team" :alt="index" id="imgSlide" />
              </slide>

              <!-- Version 1 -->
              <!-- <slide :index="0" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/CEO_glenn.png" alt="0" id="imgSlide" />
              </slide>
              <slide :index="1" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/COO_sharleen.png" alt="1" id="imgSlide" />
              </slide>
              <slide :index="2" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/REV_bayu.png" alt="2" id="imgSlide" />
              </slide>
              <slide :index="3" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/REV_amanda-risky.png" alt="3" id="imgSlide" />
              </slide>
              <slide :index="4" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/REV_dewi.png" alt="4" id="imgSlide" />
              </slide>
              <slide :index="5" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/OP_rivandi.png" alt="5" id="imgSlide" />
              </slide>
              <slide :index="6" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/OP_fazar.png" alt="6" id="imgSlide" />
              </slide>
              <slide :index="7" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/nur.png" alt="7" id="imgSlide" />
              </slide>
              <slide :index="8" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/OP_dane.png" alt="8" id="imgSlide" />
              </slide>
              <slide :index="9" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/OP_setiawan.png" alt="9" id="imgSlide" />
              </slide>
              <slide :index="10" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/OP_maryanto.png" alt="10" id="imgSlide" />
              </slide>
              <slide :index="11" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/TEC_hendri.png" alt="11" id="imgSlide" />
              </slide>
              <slide :index="12" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/TEC_peri.png" alt="12" id="imgSlide" />
              </slide>
              <slide :index="13" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/TEC_Mumtaz.png" alt="13" id="imgSlide" />
              </slide>
              <slide :index="14" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/TEC_Deni.png" alt="14" id="imgSlide" />
              </slide>
              <slide :index="15" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/TEC_rizki-a.png" alt="15" id="imgSlide" />
              </slide>
              <slide :index="16" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/TEC_anas.png" alt="16" id="imgSlide" />
              </slide>
              <slide :index="17" class="slide-container">
                <img @load="imgSlide" src="@/assets/about/TEC_michael.png" alt="17" id="imgSlide" />
              </slide> -->
            </Carousel3d>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import axios from 'axios'
export default {
  props: ['lang'],
  components: {
    Carousel3d,
    Slide
  },
  data: function () {
    return {
      loading: true,
      imgCount: 15,
      windowWidth: 0,
      windowHeight: 0,
      imgLoad: 0,
      showCarousel: false,
      slickOptions: {
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      },
      teamData: []
    }
  },
  watch: {
    lang () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  },
  methods: {
    imgSlide () {
      this.imgCount--
      if (!this.imgCount) {
        this.loading = false
      }
    },
    handleLoad () {
      this.imgLoad++
      if (this.imgLoad === 15) this.showCarousel = true
    },
    async getTeamDataFromDB () {
      try {
        const res = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_API_BASE_URL}/web/teams`
        })
        this.teamData = res.data
        this.imgCount = this.teamData.length
      } catch (error) {
        console.log(error)
      }
    }
  },
  created () {
    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight
    this.getTeamDataFromDB()
  },
  computed: {
    getHeight () {
      return `min-height: ${this.windowHeight}px`
    }
  }
}
</script>

<style scope>
/* First Content */
.first-content {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 6vh;
  min-height: calc(100vh - 3rem);
  height: 100vh;
}

#round-shape {
  position: absolute;
  z-index: -20;
  width: 250px;
  height: auto;
  left: -10rem;
  top: 3rem;
}

#round-shape {
  position: absolute;
  z-index: -20;
  width: 250px;
  height: auto;
}

.main-text {
  padding-top: 3rem;
  width: 100%;
  height: auto;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3rem;
}

.main-text h1 {
  color: #0245ab;
  margin-bottom: 2px;
  font-size: 1.8rem;
  font-weight: 700;
}

.main-text p {
  font-size: .9rem;
  color: black;
  width: 55%;
  line-height: 1.8;
}

.back-container {
  width: 100%;
  height: 50vh;
  position: absolute;
  bottom: 0;
  min-height: 50%;
}

.balik-layar {
  width: 100%;
  height: 56vh;
  min-height: 324px;
  position: relative;
}

.balik-layar .text h1 {
  text-align: right;
  font-size: 1.8rem !important;
  /* margin-right: 55p?x; */
  color: white;
  text-transform: uppercase;
  /* margin-bottom: 5%?; */
  margin-left: auto;
  margin-right: 15%;
  /* margin-top: 2%; */
}

.balik-layar .text {
  position: absolute;
  width: 100%;
}

.balik-layar .people-profile {
  width: 100%;
  min-height: 66vh;
  display: grid;
  place-items: center;
  z-index: 88;
}

.slide-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.slide-container #imgSlide {
  width: 300px;
  height: auto;
  margin: 0 5px;

  border-radius: 50%;

  -webkit-box-shadow: 0px 4px 12px -2px rgba(138, 133, 138, 1);
  -moz-box-shadow: 0px 4px 12px -2px rgba(138, 133, 138, 1);
  box-shadow: 0px 4px 12px -2px rgba(138, 133, 138, 1);
}

/* imgSldie */
#imgSlide {
  width: 70%;
  margin: 0 15% !important;
}

/* little shape */
#rectangle {
  position: absolute;
  width: 50px;
  height: auto;
  top: 20rem;
    left: 6rem;
}

.slide-container.right-1 {
  display: block !important;
}

#wiggle-1 {
  position: absolute;
  width: 62px;
  height: auto;
      top: 32rem;
    left: 19rem;

}

#donat {
  position: absolute;
  width: 50px;
  height: auto;
  top: 26rem;
    left: 33.5rem;
}

#wiggle-2 {
  position: absolute;
  width: 70px;
  height: auto;
  top: 20rem;
    right: 22rem;
}

#circle {
  position: absolute;
  width: 50px;
  height: auto;
  top: 31rem;
    right: 12rem;
}

.carousel-3d-slide {
  background: none !important;
  border: none !important;
  height: auto !important;
}

.carousel-3d-slide img {
  width: 100%;
  height: auto;
}

.mobile-carousel {
  background-image: url('~@/assets/about/bg-top-min.png');
  /* background-attachment: fixed; */
  /* background-size: ; */
  background-repeat: no-repeat;
  background-position: top;
}

@media (min-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .slide-container {
    width: 400px !important;
    height: auto !important;
  }

  .carousel-3d-slide img {
    width: 300px !important;
    height: auto !important;
  }

  .carousel-3d-container {
    min-height: 40vh !important;
  }
}

@media screen and (min-width: 1200px) {
  .about-content, .balik-layar {
    width: 1140px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .about-content {
    width: 960px;
    margin: 0 auto;
    height: auto;
  }
/*
  .about-content p {
    font-size: 15px;
    width: 75%;
  } */

  /* .balik-layar .text h1 {
    font-size: 40px;
    margin-right: 10px;
  } */

  .content-one {
    width: 100%;
  }

  #round-shape {
    width: 220px;
    left: -9rem;
  }
}

@media screen and (max-width: 575px) {
  .main-text {
    height: 40vh;
    margin-top: 0;
    padding-left: 5%;
    padding-right: 5%;
  }

  #round-shape {
    width: 30%;
    top: 8vh;
    left: -18vw;
  }

  .main-text h1 {
    font-size: 1.5rem;
  }

  .main-text p {
    font-size: 0.9rem;
    font-weight: 450;
    width: 100%;
    margin-top: .5rem;
  }

  #backdrop-wave {
    display: none;
  }

  .balik-layar .text h1 {
    font-size: 22px !important;
    margin-right: 5%;
    margin-top: 30px;
    margin-bottom: 10px;
    z-index: 99999;
    position: relative;
    transform: translate3d(0, 0, 250px);
  }

  #circle {
    width: 30px;
    top: 49vh;
    left: 45vw;
    z-index: 1;
  }

  #wiggle-1 {
    position: absolute;
    width: 40px;
    height: auto;
    top: 84vh;
    left: 15vw;
  }

  #wiggle-2 {
    width: 30px;
    left: 80vw;
  }

  #rectangle {
    width: 40px;
    left: 70vw;
    top: 80vh;
  }

  #donat {
    top: 60vh;
    left: 18vw;
    width: 40px;
  }

    .carousel-3d-container {
    min-height: 50vh !important;
  }

}

@media screen and (min-height: 700px) {
  #circle {
    top: 53vh !important;
  }

  #wiggle-2 {
    top: 60vh;
  }

  #donat {
    top: 59vh !important;
    left: 21vw !important;
  }
}

@media screen and (min-width: 1400px) {
  #rectangle {
    top: 24.5rem;
  }

  #wiggle-1 {
    top: 42rem;
    left: 23rem;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .main-text {
    height: 14rem;
    margin-top: 0;
    padding-left: 10%;
    padding-right: 10%;
  }

  #round-shape {
    width: 200px;
    top: 4.5rem;
    left: -8rem;
  }

  .main-text p {
    font-weight: 450;
    width: 100%;
  }

  /* #backdrop-wave {
    display: none;
  } */

  .balik-layar .text h1 {
    margin-right: 5%;
    margin-bottom: 10px;
    z-index: 99999;
    position: relative;
    transform: translate3d(0, 0, 250px);
  }

  #circle {
    width: 40px;
    top: 21rem;
    left: 23rem;

  }

  #wiggle-1 {
    position: absolute;
    width: 40px;
    height: auto;
    top: 33rem;
    left: 18rem;
  }

  #wiggle-2 {
    width: 40px;
    left: 40rem;
  }

  #rectangle {
    width: 40px;
    left: 41rem;
    top: 32rem;
  }

  #donat {
    top: 22rem;
    left: 7rem;
    width: 40px;
  }

  .slide-container #imgSlide {
    width: 300px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .main-text {
    height: 14rem;
    margin-top: 0;
    padding-left: 10%;
    padding-right: 10%;
  }

  .balik-layar .text {
    padding: 0 10%;
  }

  #round-shape {
    width: 200px;
    top: 4.5rem;
    left: -8rem;
  }

  .main-text p {
    font-weight: 450;
    width: 75%;
  }

  .balik-layar .text h1 {
    margin-right: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
    z-index: 99999;
    position: relative;
    transform: translate3d(0, 0, 250px);
  }

  #circle {
    width: 40px;
    top: 21rem;
    left: 23rem;

  }

  #wiggle-1 {
    position: absolute;
    width: 40px;
    height: auto;
    top: 33rem;
    left: 18rem;
  }

  #wiggle-2 {
    width: 40px;
    left: 40rem;
  }

  #rectangle {
    width: 40px;
    left: 41rem;
    top: 32rem;
  }

  #donat {
    top: 22rem;
    left: 7rem;
    width: 40px;
  }

  .slide-container #imgSlide {
    width: 300px;
  }
}

@media (min-width: 1200px) {
  .main-text h1 {
    font-size: min(2.5vw, 28px);
  }

  .main-text p {
    font-size: min(1.3vw, 18px);
  }
}

@media (max-height: 680px) {
  .carousel-3d-slider {
    margin-top: 20px !important;
  }

  .slide-container #imgSlide {
    width: 200px;
  }
}
</style>
